import React, { Component } from 'react'
const { parse } = require('flatted/cjs')
import styles from './index.module.scss'
import findIndex from 'lodash/findIndex'
import sortBy from 'lodash/sortBy'
import AppConstants from 'AppConstants'
import AppActions from 'AppActions'
import AppStore from 'AppStore'
import Layout from 'Components/Layout'
import Submenu from './Submenu'
import SeoHead from 'Components/SeoHead'
import { navigate } from 'gatsby'
import MainBtn from 'Components/UI/MainBtn'
import ScrollingMessageWithIcon from 'Components/UI/ScrollingMessageWithIcon'
import ProductItem from 'Components/UI/ProductItem'
import { cancelablePromise, productSizes } from 'Components/Utils/helpers'

class CategoryPage extends Component {
  constructor(props) {
    super(props)
    this.submenu = React.createRef()
    this.intersectionHelper = React.createRef()
    this.isPrivateSale = this.props.pageContext.slug === 'private-sale'
    this.isSimpleSale = this.props.pageContext.slug === 'sale'
    const products = this.props.pageContext.products.map((product, index) => { return { product: parse(product), originalIndex: index, visible: true } })
    let color = [], material = [], heel_height = []
    products.forEach(item => {
      const product = item.product
      product.color.all.forEach((col) => { if (findIndex(color, { id: col }) === -1) color.push({ id: col, name: col, checked: false}) })
      product.material.all.forEach((mat) => { if (findIndex(material, { id: mat }) === -1) material.push({ id: mat, name: mat, checked: false}) })
      if (findIndex(heel_height, { id: product.heel_height.id }) === -1) heel_height.push({...product.heel_height, checked: false})
    })
    this.state = {
      name: this.props.pageContext.name,
      products: products,
      activeFilters: [],
      saleIsActive: AppStore.userIsValid(AppStore.getCustomer()) && this.isPrivateSale,
      filters: {
        color: sortBy(color, [(o) => { return o.id }]),
        size: productSizes,
        material: sortBy(material, [(o) => { return o.id }]),
        heel_height: sortBy(heel_height, [(o) => { return parseInt(o.id, 10) }])
      }
    }
  }
  componentDidMount() {
    if (this.isPrivateSale) {
      AppStore.on(AppConstants.LOGIN, this.onLogin)
      AppStore.on(AppConstants.LOGOUT, this.onLogout)
    }
    window.addEventListener('resize', this.resize)
    setTimeout(this.resize)
    this.fetchSizes()
    if (!AppStore.userIsValid(AppStore.getCustomer()) && this.isPrivateSale) setTimeout(AppActions.openPopupLoginPanel, 0)
  }
  componentDidUpdate() {
    setTimeout(this.resize)
    setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
  }
  componentWillUnmount() {
    AppStore.off(AppConstants.LOGIN, this.onLogin)
    AppStore.off(AppConstants.LOGOUT, this.onLogout)
    window.removeEventListener('resize', this.resize)
    if (this.productSizesFetch) this.productSizesFetch.cancel()
  }
  onLogin = () => {
    this.setState({ saleIsActive: true })
    if (!AppStore.userIsValid(AppStore.getCustomer()) && this.isPrivateSale) setTimeout(AppActions.openPopupLoginPanel, 0)
  }
  onLogout = () => {
    this.setState({ saleIsActive: false })
    if (!AppStore.userIsValid(AppStore.getCustomer()) && this.isPrivateSale) setTimeout(AppActions.openPopupLoginPanel, 0)
  }
  fetchSizes() {
    setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
    if (this.productSizesFetch) this.productSizesFetch.cancel()
    const ids = this.state.products.map(item => item.product.id).join(',')
    this.productSizesFetch = cancelablePromise(fetch(`${AppConstants.API_URL}available-product-sizes?id=${ids}`))
    this.productSizesFetch.promise
    .then(res => res.json())
    .then(sizes => {
      const allSizes = []
      const products = this.state.products.slice(0)
      const newProducts = []
      sizes.forEach((item, index) => {
        const product = products[index]
        const newProduct = { sizes: item.sizes, ...product }
        newProducts.push(newProduct)
          item.sizes.forEach(size => {
            if (findIndex(allSizes, { id: size.id }) === -1) allSizes.push({...size, checked: false})
          })
      })
      const finalSizes = sortBy(allSizes, [(o) => { return parseInt(o.name, 10) }])
      this.setState({
        products: newProducts,
        filters : { ...this.state.filters, size: finalSizes }
      })
    })
    .catch(e => {})
  }
  onFiltersChange = (key, value) => {
    const newFilters = this.state.filters
    const newProducts = this.state.products
    let newActiveFilters
    newFilters[key].forEach((item, index) => {
      if (item.id === value) {
        item.checked = item.checked ? false : true
        if (item.checked) {
          newActiveFilters = [...this.state.activeFilters, { key, value }]
        } else {
          const currentIndex = findIndex(this.state.activeFilters, (o) => { return (o.key === key && o.value === value) })
          newActiveFilters = [
            ...this.state.activeFilters.slice(0, currentIndex),
            ...this.state.activeFilters.slice(currentIndex + 1)
          ]
        }
        newFilters[key] = [
          ...newFilters[key].slice(0, index),
          item,
          ...newFilters[key].slice(index + 1)
        ]
      }
    })
    if (newActiveFilters.length > 0) {
      newProducts.forEach(item => {
        const product = item.product
        let isVisible = false
        newActiveFilters.forEach(filter => {
          if (filter.key === 'color' && findIndex(product.color.all, (o) => { return o === filter.value }) !== -1) isVisible = true
          if (filter.key === 'size' && findIndex(item.sizes, (o) => { return o.id === filter.value && o.in_stock === true }) !== -1) isVisible = true
          if (filter.key === 'material' && findIndex(product.material.all, (o) => { return o === filter.value }) !== -1) isVisible = true
          if (filter.key === 'heel_height' && filter.value === product.heel_height.id) isVisible = true
        })
        item.visible = isVisible
      })
    } else {
      newProducts.forEach(item => { item.visible = true})
    }
    this.setState({
      filters: newFilters,
      activeFilters: newActiveFilters,
      products: newProducts
    })
  }
  onSortByChange = (order) => {
    let products
    if (order === '') {
      products = sortBy(this.state.products, [(o) => { return o.originalIndex }])
    } else {
      products = sortBy(this.state.products, [(o) => { return parseInt(o.product.price, 10) }])
      if (order === 'desc') products.reverse()
    }
    this.setState({ products })
  }
  resetFilters = () => {
    const newFilters = this.state.filters
    const newProducts = this.state.products
    newFilters['color'].forEach(item => item.checked = false)
    newFilters['size'].forEach(item => item.checked = false)
    newFilters['material'].forEach(item => item.checked = false)
    newFilters['heel_height'].forEach(item => item.checked = false)
    newProducts.forEach(item => item.visible = true)
    this.setState({
      name: this.props.pageContext.name,
      products: newProducts,
      filters: newFilters,
      activeFilters: []
    })
  }
  resize = () => {
    this.productItemRefs.forEach(product => { if (product.current) product.current.resize()})
  }
  onProductItemOpen = (id) => {
    this.productItemRefs.forEach(product => { if (product.current && product.current.state.infosAreOpen && product.current.props.id !== id ) product.current.reset()})
  }
  render() {
    const isCustomer = AppStore.userIsValid(AppStore.getCustomer())
    this.productItemRefs = []
    const { name, products } = this.state
    const title = name.replace('&amp;', ' & ')
    const hasProducts = products.length > 0
    const productsGrid = products.map((item, index) => {
      const product = item.product
      if (item.visible) {
        const productRef = React.createRef()
        this.productItemRefs.push(productRef)
        return (
          <div key={`product-item-${product.id}-${index}`} className='u-col-6 u-col-lg-3'>
            <ProductItem
              id={`product-item-${product.id}-${index}`}
              data={{product}}
              baseUrl={AppConstants.CLOUDFRONT_CONTENT_URL}
              className=''
              lockSale={!isCustomer}
              didOpen={this.onProductItemOpen}
              pageToGo={`/${product.permalink}`}
              extraClasses={'u-down-scale--2'}
              ref={productRef}
            />
          </div>
        )
      }
    })
    return (
      <Layout>
        <SeoHead
          title={title}
          path={this.props.location.pathname}
        />
        { hasProducts &&
        <Submenu
          className={styles.submenu}
          ref={this.submenu}
          areFiltersApplied={this.state.activeFilters.length > 0}
          filters={this.state.filters}
          category={name}
          slug={this.props.pageContext.slug}
          onFiltersChange={this.onFiltersChange}
          onSortByChange={this.onSortByChange}
          resetFilters={this.resetFilters}
        />}
        { (!this.isPrivateSale && !this.isSimpleSale) && <ScrollingMessageWithIcon/> }
        <div className={`u-row u-relative`} style={{ marginTop: '-1px', marginLeft: '-1px', zIndex: 10 }}>
          {productsGrid}
          <div ref={this.intersectionHelper} className={`${styles.intersectionHelper}`}></div>
          { !hasProducts &&
            <>
              <div className='u-height--lg--60' />
              <div className={`u-height--only-lg--20`}></div>
              <div className={`${styles.noProducts} u-col-12 u-offset-0 u-col-lg-10 u-offset-lg-1 u-uppercase t-title-1`}>
                There are no more products available.<br/>Please feel free to check out another area of our e-shop.
                <div className={`u-height--only-lg--20`}></div>
                <div className='u-height--lg--20' />
                <div className={`u-col-12 u-col-lg-12`}>
                  <MainBtn
                    color='black'
                    text='continue shopping'
                    onClick={() => { navigate('/') }}
                  />
                </div>
              </div>
              <div className={`u-height--only-lg--20`}></div>
              <div className='u-height--lg--60' />
            </>
          }
        </div>
      </Layout>
    )
  }
}

export default CategoryPage
