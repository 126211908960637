import React, { Component } from 'react'
import { breadcrumbItem } from '../../../components/UI/Breadcrumbs'
import styles from './index.module.scss'
import Checkbox from 'Components/UI/Checkbox'
import AppConstants from 'AppConstants'
import WindowStore from 'WindowStore'
import SimpleTextIconButton from 'Components/UI/SimpleTextIconButton'
import BackgroundBtn from 'Components/UI/BackgroundBtn'
require('@gouch/to-title-case')

const PANEL = {
  FILTERS: 'FILTERS',
  SORT_BY: 'SORT_BY'
}

class Submenu extends Component {
  constructor(props) {
    super(props)
    this.sortByItems = [
      {
        filter: 'asc',
        title: 'Price - low to high'
      },
      {
        filter: 'desc',
        title: 'Price - high to low'
      },
      {
        filter: '',
        title: 'Most Relevant'
      }
    ]
    this.state = {
      isFiltersOpen: false,
      currentPanel: '',
      sortBy: this.sortByItems[2],
      viewType: undefined
    }
  }
  onFiltersButtonClick = (currentPanel) => {
    const close = (currentPanel === this.state.currentPanel) && this.state.isFiltersOpen
    this.setState({
      isFiltersOpen: !close,
      currentPanel
    })
  }
  onFilterClick = (key, value) => {
    this.props.onFiltersChange(key, value)
  }
  onSortBy = (order) => {
    if (order.filter === this.state.sortBy.filter) return
    this.setState({
      sortBy: order,
      isFiltersOpen: false
    }, this.props.onSortByChange(order.filter))
  }
  resetFilters = () => {
    this.setState({
      isFiltersOpen: false
    }, this.props.resetFilters)
  }
  componentDidMount() {
    window.addEventListener('resize', this.resize)
    setTimeout(this.resize, 0)
  }
  componentDidUpdate() {
    setTimeout(this.resize, 0)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }
  resize = () => {
    const viewType = WindowStore.getViewType()
    if(viewType !== this.state.viewType) {
      this.setState({
        viewType
      })
    }
  }
  render() {
    const isMobile = this.state.viewType === AppConstants.MOBILE
    const { isFiltersOpen, currentPanel } = this.state
    const filtersActive = isFiltersOpen && currentPanel === PANEL.FILTERS
    const sortByActive = isFiltersOpen && currentPanel === PANEL.SORT_BY
    return (
      <div className={`${this.props.className} u-relative u-row`}>
        <div className={`${styles.panel} ${styles.filtersPanel} ${filtersActive ? styles.panelOpen : ''} u-absolute u-pos-tl u-z-index--3 u-fit-w`}>
          <div className='u-col-md-10 u-offset-md-1'>
            <div className='u-row'>
              <div className={`${styles.filtersCol} u-col-md-3`}>
                <p className={`${styles.filterName} u-block t-title-1`}>color</p>
                <div className='u-row'>
                  {
                    this.props.filters.color.map((color, index) => (
                      <div className='u-col-3 u-col-md-12' key={`filter-color-${index}`}>
                        <Checkbox
                          id={color.id}
                          value={color.id}
                          className='t-paragraph-0 t-color-black'
                          onChange={this.onFilterClick.bind(null, 'color', color.id)}
                          label={color.name.toTitleCase()}
                          checked={color.checked}
                        />
                      </div>
                    ))
                  }
                </div>
              </div>
              <div className={`${styles.filtersCol} u-col-md-3`}>
                <p className={`${styles.filterName} u-block t-title-1`}>size</p>
                <div className='u-row'>
                  {
                    this.props.filters.size.map((size, index) => (
                      <div className='u-col-3 u-col-md-12' key={`filter-size-${index}`}>
                        <Checkbox
                          id={size.id}
                          value={size.id}
                          className='t-paragraph-0 t-color-black'
                          onChange={this.onFilterClick.bind(null, 'size', size.id)}
                          label={size.name.toTitleCase()}
                          checked={size.checked}
                        />
                      </div>
                    ))
                  }
                </div>
              </div>
              <div className={`${styles.filtersCol} u-col-md-3`}>
                <p className={`${styles.filterName} u-block t-title-1`}>material</p>
                <div className='u-row'>
                  {
                    this.props.filters.material.map((material, index) => (
                      <div className='u-col-3 u-col-md-12' key={`filter-material-${index}`}>
                        <Checkbox
                          id={material.id}
                          value={material.id}
                          className='t-paragraph-0 t-color-black'
                          onChange={this.onFilterClick.bind(null, 'material', material.id)}
                          label={material.name.toTitleCase()}
                          checked={material.checked}
                        />
                      </div>
                    ))
                  }
                </div>
              </div>
              <div className={`${styles.filtersCol} u-col-md-3`}>
                <p className={`${styles.filterName} u-block t-title-1`}>heel height</p>
                <div className='u-row'>
                  {
                    this.props.filters.heel_height.map((heel_height, index) => (
                      <div className='u-col-3 u-col-md-12' key={`filter-heel_height-${index}`}>
                        <Checkbox
                          id={heel_height.id}
                          value={heel_height.id}
                          className='t-paragraph-0 t-color-black'
                          onChange={this.onFilterClick.bind(null, 'heel_height', heel_height.id)}
                          label={heel_height.name}
                          checked={heel_height.checked}
                        />
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
            <div className='u-height--20' />
            <div className='u-fit-w u-text-center'>
              <div className='u-col-md-2 u-inline-block u-mrg-r--20'>
                <BackgroundBtn
                  className='u-pad-x u-fit-w'
                  text='Apply'
                  negative
                  highlighted
                  onClick={() => { this.setState({isFiltersOpen: false}) }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.panel} ${sortByActive ? styles.panelOpen : ''} u-absolute u-pos-tl u-z-index--3 u-fit-w`}>
          <div className='u-col-lg-10 u-offset-lg-1'>
            <ul className={`${styles.sortByPanel} u-float-r`}>
            {
              this.sortByItems.map((item, index) => {
                const active = item.filter === this.state.sortBy.filter ? 't-color-black' : ''
                return (
                  <li key={`sortby-${index}`} >
                    <button className={`${active} t-paragraph-0`} onClick={this.onSortBy.bind(null, item)}>
                      {item.title}
                    </button>
                  </li>
                )
              })
            }
            </ul>
          </div>
        </div>
        <div className={`${styles.header} u-fit-w t-bg-white u-z-index--4`}>
          <div className={`${styles.headerWrapper} u-col-lg-10 u-offset-lg-1`}>
            <p className='u-d-none u-d-lg-inline-block'>
              { breadcrumbItem('/', 'home') }
              { breadcrumbItem(`/category/${this.props.slug}`, this.props.category, `breadcrumb-${this.props.slug}`, false, true) }
            </p>
            <div className={styles.buttons}>
              {this.props.areFiltersApplied &&
              <SimpleTextIconButton className='u-mrg-r--20 u-opacity--50' text='Reset All' svgId='cross-icon' onClick={this.resetFilters} />
              }
              <button className={`u-uppercase t-title-1`} onClick={this.onFiltersButtonClick.bind(null, PANEL.FILTERS)}>
                filters
                <span className={`${styles.arrowIcon} ${filtersActive ? styles.isActive : ''} u-inline-block u-pad-x--small`}>
                  <svg className={`u-fit`}>
                    <use xlinkHref='#arrow-icon' />
                  </svg>
                </span>
              </button>
              <button className={`${styles.sortBy} u-uppercase`} onClick={this.onFiltersButtonClick.bind(null, PANEL.SORT_BY)}>
                { !isMobile && <span className={`t-color-gray t-title-1`}>sort by : </span>}
                <span className={`${styles.sortTitle} t-title-1`}>{this.state.sortBy.title}</span>
                <span className={`${styles.arrowIcon} ${sortByActive ? styles.isActive : ''} u-inline-block`}>
                  <svg className={`u-fit`}>
                    <use xlinkHref='#arrow-icon' />
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Submenu
