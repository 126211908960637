import React from 'react'
import Ticker from 'react-ticker'
import { navigate } from 'gatsby'

const ScrollingMessageWithIcon = (props) => {
  const scale = 0.4
  return (
    <div onClick={() => { navigate(`/category/sale`) }} style={{ backgroundColor: '#000000', paddingBottom: 1 }} className={`${props.className} u-btn`}>
      <Ticker
        height={60 * scale}
        direction='toRight'
        offset='100%'
        speed={2}
      >
        {(index) => (
          <div style={{ display: 'block', width: 2238 * scale, height: 60 * scale }}>
            <img src={`/images/sale_banner.jpg`}/>
          </div>
        )}
      </Ticker>
    </div>
  )
}

export default ScrollingMessageWithIcon
